// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

//window.onload = function(){  
//  var h = window.innerHeight;
//  var w = window.innerWidth;
//  alert("Browser width: " + w + ", height: " + h + ".");
//}


// WORKS
// window.onload = function(){  
//   console.log(`asdfsadf`);
//   alert("yo");
// }

// WORKS
// window.addEventListener("load", function(){
//   alert('Loaded!');
// });

// WORKS
// window.addEventListener("shown.bs.modal", function(){
//   var urlReplace = "#modal"; // make the hash the id of the modal shown
//   history.pushState(null, null, urlReplace); // push state that hash into the url
//  });

// HAS PROBLEMS
// If a pushstate has previously happened and the back button is clicked, hide any modals.
// window.on('popstate', function() { 
//   $(".modal").modal('hide');
//  });

// HAS PROBLEMS ALSO
// window.on('popstate', function() { 
//   if ($(".modal:visible").length){                                 // validate that there are visible modal
//      $(".modal").eq($(".modal:visible").length-1).modal('hide');  // hide only the last model opened
//    }
//  });``
